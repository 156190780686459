import {
  DealStages,
  ListMode,
  TradeVehicle
} from "@coxauto-ui/ccxm/interfaces";
import { CcxmReusableLabelledInsightDisplay } from "@coxauto-ui/ccxm/reusable/labelled-insight-display";
import { LinkChip } from "@interstate/components/Chip";
import { ReactNode, useMemo } from "react";
import { CheckCircleIcon } from "@interstate/components/Icons/CheckCircleIcon";

export interface CardHeaderProps {
  consumerId: string;
  htmlId: string;
  borderMode: "highlighted" | "standard";
  dealStages?: DealStages;
  consumerFirstName: string | null;
  consumerLastName: string | null;
  dealId: string;
  showNewStatusPill?: boolean;
  dealershipName: string;
  isAccepted: boolean;
  listMode: ListMode;
  recordRow: number;
  showMultiDealButton?: boolean;
  tradeVehicles: TradeVehicle[];
  handleSelectMultiDeal: (consumerId: string, dealId: string) => void;
}

const acceptedStatus = "accepted";

const mapStatusPill = {
  [acceptedStatus]: {
    color: "#c9eabf",
    text: "Accepted"
  },
  sold: {
    color: "#c9eabf",
    text: "Sold"
  },
  lost: {
    color: "#fabaaf",
    text: "Lost"
  }
};

export function CardHeader({
  consumerId,
  consumerFirstName,
  consumerLastName,
  dealId,
  htmlId,
  borderMode,
  showNewStatusPill,
  dealershipName,
  isAccepted,
  listMode,
  dealStages,
  recordRow,
  showMultiDealButton = false,
  tradeVehicles,
  handleSelectMultiDeal
}: CardHeaderProps) {
  const consumerFullName = useMemo(() => {
    return consumerFirstName || consumerLastName
      ? [consumerFirstName || "--", consumerLastName || "--"].join(" ")
      : "--";
  }, [consumerFirstName, consumerLastName]);

  const statusPill = useMemo(() => {
    if (!showNewStatusPill && isAccepted) {
      return mapStatusPill[acceptedStatus];
    }

    const status = isAccepted ? acceptedStatus : dealStages?.status;
    const allowedStatus = Object.keys(mapStatusPill);
    const isNotDesktop = listMode !== ListMode.desktopList;
    if (
      showNewStatusPill &&
      status &&
      isNotDesktop &&
      allowedStatus.includes(status)
    ) {
      return mapStatusPill[status as keyof typeof mapStatusPill];
    }
    return null;
  }, [listMode, dealStages?.status, showNewStatusPill]);

  // interstate does not provide a type for this event
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMultiDealClick = (e: any) => {
    e.stopPropagation();
    handleSelectMultiDeal(consumerId, dealId);
  };

  const headerContent = useMemo(() => {
    const content: {
      className?: string;
      id: string;
      "data-bordermode"?: string;
      "data-consumerid"?: string;
      "data-consumername"?: string;
      "data-testid": string;
      value: ReactNode;
    }[] = [
      {
        className: "consumer-name",
        "data-bordermode": `consumer-${recordRow}-${borderMode}`,
        "data-consumerid": consumerId,
        "data-consumername": "consumer-insight-record-full-name",
        "data-testid": `consumer-${recordRow}-full-name`,
        id: `${htmlId}-${dealId}-consumer-name`,
        value: consumerFullName
      },
      {
        className: "dealership-name",
        "data-testid": `${htmlId}-${recordRow}-dealership-name`,
        id: `${htmlId}-${dealId}-dealership-name`,
        value: dealershipName
      }
    ];

    if (tradeVehicles.length > 0) {
      content.push({
        "data-testid": `${htmlId}-${recordRow}-trade-in`,
        id: `${htmlId}-${dealId}-trade-in`,
        value: (
          <span className="has-trade-in">
            <CheckCircleIcon color="sem.color.on-surface.success.muted" />
            <span>Trade-in</span>
          </span>
        )
      });
    }

    return content;
  }, [
    borderMode,
    consumerFullName,
    consumerId,
    dealId,
    dealershipName,
    recordRow,
    tradeVehicles.length
  ]);

  return (
    <CcxmReusableLabelledInsightDisplay
      appendedContent={
        (showMultiDealButton || statusPill) && (
          <>
            {showMultiDealButton && (
              <LinkChip
                className="multi-deal-button"
                data-testid={`${htmlId}-${recordRow}-multi-deal-button`}
                id={`${htmlId}-${dealId}-multi-deal-button`}
                label="+ Show All Deals"
                onClick={onMultiDealClick}
              />
            )}
            {statusPill && (
              <span
                className="status-pill"
                data-testid={`${htmlId}-${recordRow}-accepted`}
                id={`${htmlId}-${dealId}-accepted`}
                style={{ backgroundColor: statusPill.color }}
              >
                {statusPill.text}
              </span>
            )}
          </>
        )
      }
      className="card-header"
      content={headerContent}
      contentDelimiter={"|"}
      data-testid={`${htmlId}-${recordRow}-header`}
      htmlId={`${htmlId}-${dealId}-header`}
      multiline={
        listMode === ListMode.desktopCustomer ||
        listMode === ListMode.mobileList
      }
    />
  );
}

export default CardHeader;
